.ApprovalFlowTracker {
  /*  &__step {
    // background-color: blue;
    :global(.MuiStepLabel-label) {
      font-size: 12px;
      color: var(--navy-700);
      font-family: var(--font-normal);
      white-space: pre-wrap;
      // line-height: 8px;
    }
    :global(.MuiStepConnector-root) {
      height: 4px;
      background-color: #cccdd6;
      border-radius: 8px;
      top: 6px;
      left: calc(-50% + 14px);
      right: calc(50% + 14px);
      &:global(.Mui-active) {
        background-color: var(--green-700);
      }
    }
    :global(.MuiStepConnector-line) {
      display: none;
    }
  } */

  &__icon {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: var(--green-700);

    :global(.MuiSvgIcon-root) {
      width: 12px;
      height: 12px;
      color: white;
      border-radius: 100%;
    }
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: var(--bosscat-blue-600);
  cursor: pointer;
  border-radius: 8px;
  opacity: 1;
  transition: opacity ease-in-out 0.2s;

  &:hover {
    opacity: 0.8;
  }
}

.modalAccept {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: var(--bosscat-blue-600);
  cursor: pointer;
  border-radius: 8px;
  opacity: 1;
  transition: opacity ease-in-out 0.2s;
  width: 48%;
  color: #fff;

  &:hover {
    opacity: 0.8;
  }
}

.modalContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 16px
}

.modalTitle {
  font-size: 20px !important;
  font-family: 'LatoBold';
  text-align: center;
}

.modalButtonsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 48px;
  width: 100%;
  justify-content: space-between;
}

.modalCancel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: white;
  border: 1px solid var(--bosscat-blue-600);
  cursor: pointer;
  border-radius: 8px;
  width: 48%;
}