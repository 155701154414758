.header {
  margin: 40px 30px 0px;
}
.root {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 3rem;
  .title {
    font-family: 'Moranga';
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    text-align: center;
    line-height: 1;
  }
  .subtitle {
    font-family: var(--font-medium);
    font-size: var(--font-XL);
    text-align: center;
  }
  .button{
    min-width: 220px !important;
  }
}
