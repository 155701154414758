.ApprovalFlowTracker {
  &__stepper {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
  }

  &__step {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    width: 100%;
    gap: 1rem;
  }

  &__connector {
    height: 4px;
    background-color: #cccdd6;
    border-radius: 8px;
    top: 6px;
    left: calc(-50% + 14px);
    right: calc(50% + 14px);
    position: absolute;
    &__completed {
      background-color: var(--green-700);
    }
  }

  &__label {
    font-size: 12px;
    color: var(--navy-700);
    font-family: var(--font-normal);
    white-space: pre-wrap;
    text-align: center;
  }

  &__completedOn {
    width: 70px;
    word-wrap: break-word;
    text-align: center;
    color: var(--gray500);
    font-size: 10px;
  }

  &__icon {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    position: relative;
    :global(.MuiSvgIcon-root) {
      width: 12px;
      height: 12px;
      color: white;
      border-radius: 100%;
    }
  }
}
