.editButton {
  background-color: white !important;
  border: none !important;
  color: var(--bosscat-blue-600) !important;
  box-shadow: 0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31);

  margin-right: 10px;
  &:hover {
    background-color: #ec971f;
    border-color: #d58512;
  }
}

.searchInput{
  height: 100%;
  :global(.MuiFormControl-root){
    height: 100%;
  }
  :global(.MuiInputBase-root){
    height: 100%;
    border-radius: 32px;
  }
}