.Inputs {
  max-width: calc(100vw - 24px);
  &__statusSearch {
    padding-top: 0 !important;
    :global(.MuiOutlinedInput-root) {
      min-height: 3rem;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
