@import url("./assets/fonts/fonts.css");

:root {
  /* Variables for colors definition **/
  --white-color: #FFF;
  --black-color: #0F102C;
  --border-color: #EBECEF;
  --border-hover: #00b0f0;

  --navy-600: #263E7D;
  --navy-700: #1A2A55;

  --blue100: #FAFBFF;
  --blue200: #E5F7FF;
  --blue300: #B8C8FF;
  --blue400: #ADE6FF;
  --blue700: #3A66FF;
  --blue800: #0071A3;

  --purple100: #F1F0FF;
  --purple200: #E3E0FF;
  --purple300: #C1BCFF;
  --purple400: #978FFF;
  --purple500: #6E5BE8;

  --gray100: #F9FAFA;
  --gray200: #EBECEF;
  --gray400: #AAACBB;
  --gray500: #72748D;
  --gray600: #5B5D71;
  --gray700: #494A5A;
  --gray800: #F5F9F2;

  --orange100: #FFFBF0;
  --orange700: #AD7D00;

  --green100: #EDFDF6;
  --green600: #3AE49C;
  --green700: #1CCD83;
  --green800: #17B975;
  --green900: #0E905A;

  --lightBlue100: #FAFDFF;
  --lightBlue700: #0095D6;
  --lightBlue800: #0071A3;

  --greyBlue200: #E5F7FF;

  --red100: #FFF4F4;
  --red200: #FCC;
  --red500: #EB5151;
  --red600: #E01F1F;
  --red700: #CA0000;
  --red800: #990000;

  --error-color: #EB5151;
  --success-color: #1CCD83;
  --orange500: #EBA900;

  /* Variables for fonts */
  --font-light: 'NouvelleLight';
  --font-normal: 'NouvelleNormal';
  --font-medium: 'Nouvelle';
  --font-bold: 'NouvelleBold';
  --font-moranga: 'Moranga';

  --font-XXXS: 10px;
  --font-XXS: 12px;
  --font-XS: 14px;
  --font-S: 16px;
  --font-M: 18px;
  --font-L: 20px;
  --font-XL: 32px;
  --font-XXL: 36px;

  /* BOSSCAT COLORS ESTIMATE V2*/

  --bosscat-black-200: #CECDCF;
  --bosscat-black-400: #6D6A6F;
  --bosscat-black-500: #3C383F;
  --bosscat-black-600: #0B060F;
  --bosscat-black-700: #09050C;
  --bosscat-black-1000: #091931;
  --bosscat-blue-100: #EAF2FE;
  --bosscat-blue-300: #ACCAFC;
  --bosscat-blue-600: #2F7BF7;
  --bosscat-blue-700: #2662C6;
  --bosscat-blue-800: #1C4A94;
  --bosscat-blue-900: #133163;
  --bosscat-blue-1000: #091931;
  --repairs-yellow-100: #FFF9EA;
  --repairs-yellow-500: #FFD15A;
  --repairs-yellow-600: #FFC531;
  --repairs-yellow-700: #CC9E27;
  --repairs-yellow-800: #99761D;
  --bosscat-green-100: #E6F7EE;
  --bosscat-green-500: #34BB7A;
  --bosscat-green-600: #01AA59;
  --bosscat-green-700: #018847;
  --bosscat-green-800: #016635;
  --bosscat-green-900: #004424;
  --hardware-haze-100: #F6F5EF;
  --hardware-haze-400: #C9C09C;
  --hardware-haze-600: #A5965A;
  --hardware-haze-700: #847848;
  --hardware-haze-1000: #211E12;
  --handyman-green-700: #1A3E2A;
  --bosscat-grey-200: #FCFCFC;
  --bosscat-grey-300: #F9F9F9;
  --bosscat-grey-400: #F5F6F7;
  --bosscat-grey-800: #8F9091;
  --bosscat-grey-1000: #303030;
  --renovation-red-100: #FFEEEA;
  --renovation-red-600: #FF5031;
  --renovation-red-700: #CC4027;
  --renovation-red-800: #99301D;
  --renovation-red-900: #662014;
  --handyman-green-600: #214E34;
  --handyman-green-900: #0D1F15;
  --border-border-error-light: #FFB9AD;
  --border-border-info-light: #ACCAFC;
  --border-border: #F5F6F7;
  --border-border-info: #2F7BF7;
  --text-text-primary: #0B060F;
  --text-text-info: #2F7BF7;
  --text-text-success: #01AA59;
  --text-text-tertiary: #9D9B9F;
  --text-text-warning: #CC9E27;
  --background-bg-soft-3: #F5F6F7 --background-color-primary: #FDFEFE;
  /*#E5E5E5;*/
  --background-color-secondary: #FCFCFC;
  --background-color-disabled: #F9F9F9;
  --button-secondary: #33C0FF;
  --button-secondary-hover: #0095D6;
  --small-button-color: #1CCD83;
  --small-button-focus: #0E915A;
  --button-focus-color: #2662C6;
  --border-color-focus: #EFF0F1;
  --placeholder-color: #9D9B9F;
  --input-color: #0B060F;
  --text-color: #5B5D71;
  --head-text-color: #1A2A55;
  --row-focus: #E5F7FF;
  --row-active: #CEF0FF;
  --row-background: #FAFDFF;
  --line-color: #ADE6FF;
  --error-color: #FF9393;
  --teal-700: #0CA2B5;
  --profile-picture-button-bg: rgba(26, 42, 85, 0.5);
  --delete-button-color: #EB5151;
  --button-bar: #F0F3FA;
  --date-color: #72748D;
  --primary-outlined-resting-border: rgba(11, 94, 170, 0.5);
  --better-color: #018847;
  --better-light-color: #01AA59;
  --better-bold-color: #016635;
  --better-delete-color: rgba(191, 39, 27, 1);
  --punchlist-red: #FF5031;
  --orange-300: #FFD874;
  --green-700: #1CCD83;
  --green-300: #B7F5DB;
  --green-100: #EDFDF6;

  /* BOSSCAT COLORS ESTIMATE V2*/

  /*****react-tostify-override***********************/
  --toastify-color-info: rgb(232, 244, 253);
  --toastify-color-success: rgba(239, 247, 238, 1);
  --toastify-color-warning: rgb(255, 244, 229);
  --toastify-color-error: rgb(139, 109, 106);

  --toastify-toast-width: 400px;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;

  /*
  --toastify-text-color-info: rgb(13, 60, 97);
  --toastify-text-color-success:#17B975;
  --toastify-text-color-warning: rgb(102, 60, 0);
  --toastify-text-color-error: rgb(97, 26, 21);
   --toastify-text-color-success: #1A2A55;
   --toastify-content-success:#F9FAFA;
  --toastify-border-radius:12px;
*/
  --toastify-font-family: 'Moranga';
  --toastify-text-color-success: #17B975;

  --toastify-text-color-info: #3A66FF;
  --toastify-text-color-warning: #AD7D00;
  --toastify-text-color-error: #CA0000;



  /**************************************************/
}

.Toastify__toast--success {
  border: 2px solid #93F0C9 !important;
  border-radius: 12px !important;
  background: #F9FAFA !important;
}

.Toastify__toast--success .MuiTypography-h5Bold {
  color: #0E905A !important
}

.Toastify__toast--info {
  border: 2px solid #B8C8FF !important;
  border-radius: 12px !important;
  background: #F9FAFA !important;
}

.Toastify__toast--info .MuiTypography-h5Bold {
  color: #3A66FF !important
}

.Toastify__toast--warning {
  border: 2px solid #FFD874 !important;
  border-radius: 12px !important;
  background: #FFFBF0 !important;
}

.Toastify__toast--warning .MuiTypography-h5Bold {
  color: #1A2A55
}

.Toastify__toast--error {
  border: 2px solid #FFCCCC !important;
  border-radius: 12px !important;
  background: #FFF4F4 !important;
}

.Toastify__toast--error .MuiTypography-h5Bold {
  color: #990000 !important
}

.toast-text-tittle {
  color: #1A2A55 !important;
  font-size: 14px !important;
  font-family: 'Moranga' !important;
}

.toast-text-subTittle {
  color: #1A2A55 !important;
  font-size: 12px !important;
  font-family: 'Nouvelle' !important;
}

.Toastify__toast-body {
  color: #1A2A55 !important;
  font-family: 'NouvelleNormal' !important;
  font-size: 16px !important;
}

.Toastify__toast--success .Toastify__toast-icon {
  color: #17B975 !important;
}

.Toastify__toast--error .Toastify__toast-icon {
  color: #CA0000 !important;
}

.Toastify__toast--warning .Toastify__toast-icon {
  color: #AD7D00 !important;
}

.Toastify__toast--info .Toastify__toast-icon {
  color: #3A66FF !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-color-primary);

  /*NoSelect*/
  /*-webkit-touch-callout: none;*/
  /* iOS Safari */
  /*-webkit-user-select: none;*/
  /* Safari */
  /*-khtml-user-select: none;*/
  /* Konqueror HTML */
  /*-moz-user-select: none;*/
  /* Firefox */
  /*-ms-user-select: none;*/
  /* Internet Explorer/Edge */
  /*user-select: none;*/
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*****hide-autofill********************************/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

/**************************************************/

/*****hide-number-arrows***************************/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

/**************************************************/

/*****hide-blue-highlight**************************/
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  color: inherit;
  text-decoration: none;
}

/**************************************************/

/*****custom-scrollbar*****************************/
::-webkit-scrollbar {
  width: 20px;
  height: 18px;
}

.dark::-webkit-scrollbar {
  background-color: var(--background-dark);
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 8px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 26px;
  border-radius: 26px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

/**************************************************/

/*****react-html5-camera-photo-override************/
@keyframes disabledCamera {
  0% {
    visibility: hidden;
  }

  75% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

#container-circles {
  position: absolute !important;
  left: 50% !important;
  bottom: 75px !important;
  animation: disabledCamera 1.5s !important;

}

#inner-circle,
#outer-circle {
  border-radius: 50%;
}

#outer-circle {
  position: absolute;
  left: -37px !important;
  height: 64px !important;
  width: 64px !important;
  background-color: hsla(0, 0%, 100%, .4);
  z-index: 1;
  display: flex;
}

#inner-circle {
  left: 60% !important;
  top: 39px !important;
  height: 32px !important;
  width: 32px !important;
  background: #fff;
  margin: -22px 0 0 -22px !important;
  z-index: 2;
}

/**************************************************/

/*****react-google-maps/api-override***************/

/* Google link */
a[href^="http://maps.google.com/maps"] {
  display: none !important
}

a[href^="https://maps.google.com/maps"] {
  display: none !important
}

/* Add theme styles to tab buttons */
.gm-style-mtc button {
  font-size: 12px !important;
  height: 30px !important;
  border-color: #EBECEF !important;
  float: right !important;
}

/* Disable view options */
.gmnoprint ul {
  display: none;
}

/* Align buttons right  */
[role='menubar'] {
  right: 0px !important;
  left: auto !important;
  float: right !important;
}

/* Apply theme color when selected */
.gm-style-mtc [aria-checked='true'] {
  background-color: var(--blue700) !important;
  color: white !important;
  padding: 0.4rem;
}

/* Bottom line */
.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

/**************************************************/

/*****react-tostify-override***********************/
@media only screen and (max-width: 480px) {
  .Toastify__toast-theme--colored {
    margin: 16px;
  }
}

.Toastify__close-button>svg {
  color: var(--black-color)
}

.Toastify__toast-container--top-right {
  top: 0.3rem;
  right: 0;
}

.Toastify__toast-body {
  align-items: flex-start;
}

/**************************************************/

@keyframes loginAnimationFadeInOut {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#loginAnimation img {
  animation-name: 'loginAnimationFadeInOut';
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 10s;
  animation-direction: alternate;
}

/**************************************************/

.rdrDefinedRangesWrapper {
  width: 0px !important;
}


/**************************************************/

ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  list-style: none;
}

/**************************************************/

.ReactModal__Overlay {
  z-index: 1300 !important;
}

/************************ CALENDAR **************************/

.rdrMonthAndYearWrapper {
  padding-top: 0;
  height: fit-content;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background-color: var(--blue700);
}

/* .Comments__textarea{
  overflow: scroll;
} */

.Comments__textarea__input {
  overflow-y: scroll !important;
}

.Comments__textarea__suggestions {
  border: 2px solid var(--border-color);
  padding: 1rem;
  top: inherit !important;
  bottom: 4rem !important
}

.swiper-button-prev-slider:after {
  background-image: url(./assets/icons/arrow-prev.svg) !important;
  background-repeat: no-repeat;
  content: '';
  width: 12px;
  height: 12px;
}

.swiper-button-prev-slider,
.swiper-button-next-slider {
  cursor: pointer;
}

.swiper-button-next:after {
  background-image: url(./assets/icons/arrow-next.svg) !important;
  background-repeat: no-repeat;
  content: '';
  width: 16px;
  height: 16px;
}

.swiper-button-prev:after {
  background-image: url(./assets/icons/arrow-prev.svg) !important;
  background-repeat: no-repeat;
  content: '';
  width: 16px;
  height: 16px;
}

.swiper-button-prev-slider:after {
  background-image: url(./assets/icons/arrow-next.svg) !important;
  background-repeat: no-repeat;
  content: '';
  width: 12px;
  height: 12px;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  z-index: 99;
  width: fit-content;
}

.swiper-button-next-unique {
  transform: translate(0%, -50%);
  right: 0
}

.swiper-button-prev-unique {
  transform: translate(0%, -50%);
  left: 0
}

.swiper-button-next-slider2 {
  transform: translate(0%, -50%);
  right: 0
}

.swiper-button-prev-slider2 {
  transform: translate(0%, -50%);
  left: 0
}

#font-picker-fontpicker1,
#font-picker,
#font-picker-fontpicker2 {
  width: 100%;
  border: 2px solid var(--border-color);
  border-radius: 8px;
  padding: 1px 0px;
  box-shadow: 0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)
}

#font-picker-fontpicker1 .dropdown-button,
#font-picker-fontpicker2 .dropdown-button,
#font-picker .dropdown-button {
  background: transparent
}

div[id^=font-picker] ul {
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px
}