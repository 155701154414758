.selected {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  border: 2px solid var(--blue700);
  background-color: var(--greyBlue200);
  align-items: center;
}
.unselected {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  border: 2px solid var(--gray200);
  align-items: center;
}