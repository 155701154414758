.RemovedItemDetail {
  cursor: pointer;
  transition: 300ms all;
  padding: 0.5rem 1rem;
  border-bottom: 2px solid var(--border-color);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  &:hover {
    background: #cef0ff;
    transition: 300ms all;
  }
  &__selected {
    background: #cef0ff;
  }
}
