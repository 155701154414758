.Companies {
  &__companyName {
    font-family: var(--font-normal) !important;
    font-style: 'normal';
    font-weight: 400;
    font-size: var(--font-XS) !important;
    line-height: '22px !important';
    // line-break: anywhere;
    color: '#1A2A55';
  }

  &__createdOn {
    font-family: var(--font-normal) !important;
    font-style: 'normal';
    font-weight: 400;
    font-size: var(--font-XXS) !important;
    line-height: '18px !important';
    // line-break: anywhere;
    color: '#72748D !important';
  }

  &__viewButton {
    width: 91px;
    height: 32px !important;
  }

  &__tableContainer {
    height: calc(100vh - 270px);
    overflow: overlay;
  }
}

.workOrdersTable {
  width: 100%;
  max-width: 97vw;
  overflow: visible;

  :global(.MuiTable-root) {
    :global(.MuiTableRow-root) {
      :global(.MuiTableCell-stickyHeader) {
        background-color: var(--gray100);
      }
    }
  }
}


.TableScrollBar::-webkit-scrollbar {
  height: 25px;
  width: 25px;
  transition: all 0.5s ease-in-out;
}

.TableScrollBar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.75);
}

.filters {
  flex-wrap: 'wrap';
  margin-top: -12px;
  margin-bottom: 12px;
  align-items: flex-end;
}

.collapseButton {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  background-color: var(--gray700);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease-out 0s;
  cursor: pointer;
}

.collapseButtonCollapsed {
  transform: rotate(90deg);
}

.collapseButtonNotCollapsed {
  transform: rotate(-90deg);
}

.topInfo {
  transition: all 0.5s ease-in-out 0s;
}

.topInfoCollapsed {
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.topInfoNotCollapsed {
  height: auto;
  opacity: 1;
}
.emptyState {
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.downloadButtonAction {
  height: 56px;
}