.Jobs {
  &__cell {
    padding: 2px 10px !important;
    &__content {
      font-size: var(--font-XS) !important;
      line-height: 22px !important;
      font-weight: 400 !important;
    }
  }
}
