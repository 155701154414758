.Table {
  max-width: 100%;
  overflow: visible;
  position: relative;
  //border: 2px solid var(--border-color);

  &__hasScroll {
    max-width: calc(100vw - 48px);
    overflow: scroll;
  }

  &__Header {
    display: flex;
    justify-content: center;
    background-color: var(--blue700);
    padding: 8px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    transform: translateY(2px);
  }

  &__TopBorders {
    border-radius: 14px;
  }

  .loading {
    // position: absolute;
    left: 50%;
    top: 55%;
  }

  .emptyState {
    // position: absolute;
    // left: 40%;
    // top: 50%;
  }

  .loadingState {
    position: fixed;
    left: 48.5%;

  }

  .loadingStateWrapper {
    position: relative;
    padding: 100px 50px;
  }
}