.Services {
  // margin-top: 2rem;
  :global(.MuiTabs-root) {
    margin: 0 !important;
  }

  &Tab {
    :global(.MuiButtonBase-root) {
      font-size: var(--font-S);
    }
  }
}
