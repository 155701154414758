
.root{
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
    padding: '32px 32px';
}

.contianer {
    display: 'flex';
  }

.containerDescription{
    display: 'flex';
    flex-direction: 'column';
    justify-content: 'space-around';
  }

.ilustration {
    width: '128px';
    align-self: 'center';
    margin: '0px 32px 0px 0px';
  }

title{
    font-size: '22px';
    //font-family: 'Moranga';
  }

.description {
    font-size: '16px';
  }

  .loading {
    position: absolute;
    left: 50%;
    top: 55%;
  }

  .emptyState{
    position: absolute;
    left: 40%;
    top: 50%;
  }
