.Table {
  :global(.MuiTable-root) {
    border: none;
    border-radius: 0;
  }

  :global(.MuiTableRow-root) {
    position: relative;
  }

  :global(.MuiTableCell-body) {
    vertical-align: baseline;
  }
}

.Actions {
  position: absolute;
  display: flex;
  right: 16px;
  bottom: 8px;
  gap: 8px;
}

.DropDown {
  :global(.MuiAccordionSummary-root) {
    background-color: var(--blue700);
    padding: 0 0.5rem !important;
  }

  :global(.MuiAccordionSummary-expandIconWrapper) {
    :global(.MuiSvgIcon-root) {
      color: white !important;
    }
  }
}

.Tabs {
  .All {
    background-color: transparent !important;
    border: 2px solid '#EBECEF' !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }
}