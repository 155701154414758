.TabNavigation {
  :global(.MuiTab-root) {
    font-size: var(--font-XS) !important;
  }
}

.rejectedTableItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.25rem;
}

.rejectedTableItemText {
  padding: 0.5rem;
  border: 1px solid var(--gray600);
}

.rejectedTableItemText2 {
  padding: 0.5rem;
  border: 1px solid var(--gray600);
  border-left: none;
}