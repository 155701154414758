.ClientNote {
    &__address {
        font-size: 14px !important;
        line-height: 22px !important;
        font-weight: 400;
        color: #3A66FF;
        background-color: #F9FAFA;
        font-weight: bold;
    }

    &__noteTitle {
        font-size: 14px !important;
        line-height: 22px !important;
        font-weight: 600 !important;
    }
}