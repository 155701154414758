.Button {
  &__loading {
    justify-content: center !important;
  }

  &__onClick {
    &__container {
      border: 0 !important;
      height: 100% !important;
      overflow: hidden;
      padding: 0 !important;

      &:hover {
        background-color: var(--blue700);
        padding: 0;
      }

      :global(.MuiButton-endIcon) {
        padding: 0.5rem 0.7rem;
        margin: 0;
        box-shadow: 7px 0px 17px 1px rgb(0 0 0 / 17%);
        transform: scaleY(1.2);

        &:hover {
          background-color: var(--blue800);
          transition: 300ms all;
        }
      }

      &__action {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: var(--blue800);
          transition: 300ms all;
        }
      }
    }
  }
}