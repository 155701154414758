.Companies {
  &__companyName {
    font-family: var(--font-normal) !important;
    font-style: 'normal';
    font-weight: 400;
    font-size: var(--font-XS) !important;
    line-height: '22px !important';
    // line-break: anywhere;
    color: '#1A2A55';
  }

  &__createdOn {
    font-family: var(--font-normal) !important;
    font-style: 'normal';
    font-weight: 400;
    font-size: var(--font-XXS) !important;
    line-height: '18px !important';
    // line-break: anywhere;
    color: '#72748D !important';
  }

  &__viewButton {
    width: 91px;
    height: 32px !important;
  }

  &__tableContainer {
    height: calc(100vh - 270px);
    overflow: overlay;
  }
}

.workOrdersTable {
  width: 100%;
  max-width: 97vw;
  overflow: visible;

  :global(.MuiTable-root) {
    :global(.MuiTableRow-root) {
      :global(.MuiTableCell-stickyHeader) {
        background-color: var(--gray100);
      }
    }
  }
}

.TableScrollBar::-webkit-scrollbar {
  height: 25px;
  width: 25px;
}

.TableScrollBar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.75);
}

.editItem {
  cursor: pointer;
  color: var(--blue700);
}