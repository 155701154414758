.OrderItem {
  cursor: pointer;
  border: 2px solid var(--border-color);
  border-radius: 8px;
  padding: 0.2rem 1rem;
  &:hover {
    border: 2px solid #3a66ff;
    transition: 300ms all;
  }
  &__selected {
    border: 2px solid #3a66ff;
    background-color: #cef0ff;
    transition: 300ms all;
  }
}
