.ColorPicker {
  padding: 12px;
  min-width: 360px;
  &__container {
    border: none;
    padding: 0 !important;
    box-shadow: none !important;
    :global(.flexbox-fix) {
      display: none !important;
      &:nth-child(2) {
        padding: 12px;
        display: flex !important;
        flex-direction: row-reverse;
        gap: 1rem;
        > div:nth-child(2) {
          > div {
            border-radius: 100px !important;
            box-shadow: none !important;
          }
        }
      }
    }
  }
  &__popover {
    :global(.MuiPaper-root) {
      border-radius: 12px;
      border: 2px solid #ebecef;
      box-shadow: 0px 3px 5px rgba(26, 42, 85, 0.2),
        0px 0px 1px rgba(26, 42, 85, 0.31);
    }
  }
}
