.buttonBack {
  background-color: #f0f3fa !important;
  position: absolute !important;
  left: 0;
  width: 28px !important;
  height: 26px !important;
  border-radius: 0px 8px 8px 0px !important;
  padding: 0 !important;
  &__arrow {
    color: var(--bosscat-blue-600);
    width: 16px
  }
}
