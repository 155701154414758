.DropDown {
  :global(.MuiAccordionSummary-root) {
    background-color: var(--blue700);
    padding: 0 0.5rem !important;
  }

  :global(.MuiAccordionSummary-expandIconWrapper) {
    :global(.MuiSvgIcon-root) {
      color: white !important;
    }
  }

  :global(.MuiAccordionSummary-content) {
    flex: 1;
  }
}