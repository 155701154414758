.SuccessManagerOptionsIcon{ 
    color: green;
    margin-right: 5px
}

.controlButton {
    box-shadow: 0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31);
    border: 0px !important;
    font-size: 12px;
    line-height: 18px;
    gap: 4px;
    &:hover {
        border: 0;
    }
}

.icon {
    height: 16px;
    width: 16px;
}
