.Modal {
  :global(.MuiPaper-root) {
    overflow: visible;
    min-height: 30vh !important;
    min-width: 100vh !important;
  }

  &__contactStyle {
    font-size: 12px;
    line-height: 18px;
    word-break: break-all;
    text-align: justify;
  }
}