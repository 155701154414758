.Company {
  &__logoContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    height: fit-content;
    color: #1a2a55;

    &__logo {
      width: 32px;
      height: 32px;
    }

    &__name {
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-L) !important;
      line-height: 30px !important;
    }
  }

  &__label {
    font-size: 14px !important;
    line-height: 22px !important;
    font-weight: 500;
  }
}
