.rbc-date-cell {
  text-align: center;
  padding-top: 4px;
}

.rbc-month-header {
  /*border: 0px;*/
  font-size: 14px;
  text-transform: uppercase;
  color: #494a5a;
  background: #f9fafa;
}

.rbc-month-row {
  height: fit-content;
  overflow: visible;
}

.rbc-header {
  /*border: 0px;*/
  font-size: 14px;
  text-transform: uppercase;
  color: #494a5a;
  background: #f9fafa;
}

.rbc-row-segment {
  padding: 2px 4px;
}

.rbc-event-content {
  white-space: break-spaces !important;
  background-color: white !important;
}

.rbc-event-continues-prior {
  border-radius: 4px !important;
}

.rbc-overlay {
  max-height: 200px;
  overflow: scroll;
}

.rbc-event {
  padding: 0 !important;
  min-height: 44px !important;
  border: none !important
}

.rbc-event-allday {
  height: fit-content !important;
  min-height: fit-content !important;
  overflow: hidden;
}

.rbc-event-label {
  display: none !important;
}

.rbc-time-header {
  height: fit-content;
  max-height: 150px !important;
  margin-right: 0px !important;
  overflow: scroll;
}

.rbc-allday-cell .event-container p {
  display: inline !important;
}

.rbc-allday-cell .event-container .event-subtitle::before {
  content: "-";
  margin: .2rem
}