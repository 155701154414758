.Tabs {
  .AddNew {
    background-color: transparent !important;
    color: var(--blue700) !important;
    border: 2px solid var(--blue700) !important;
    border-bottom: none !important;
  }
}

.CommentButton {
  position: fixed !important;
  bottom: 2rem !important;
  right: 3rem !important;
  width: 72px !important;
  height: 72px !important;
  border-radius: 72px !important;
  padding: 1rem !important;

  transition: width 1s ease-in-out !important;
  &:hover {
    background-color: var(--purple500) !important;
    width: 180px !important;
    transition: width 1s ease-in-out !important;
  }

  &__text {
    font-size: 24px !important;
    margin-left: 0.5rem !important;
    transition: width 200ms ease-in-out !important;
  }
}
