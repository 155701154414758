.Expand {
  &__button {
    background-color: var(--navy-700) !important;
    color: var(--white-color) !important;
    width: 78px !important;
    right: 24px;
    border-radius: 8px 8px 0px 0px !important;
  }

  &__expanded {
    position: absolute;
    top: 0 !important;
    padding: 2rem 48px 24px 0px;
    background: white;
    transition: top 3s linear;
    &__noPadding {
      padding: 0 !important;
    }
    &.Expand__button {
      right: 48px;
      transform: rotate(180deg);
      border-radius: 0px 8px 0px 0px !important;
    }
  }
}
