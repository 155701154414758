.PreviewInvoice {
  &__container {
    padding: 24px;
    padding-right: 0;
    min-height: 90vh;
  }
  &__left {
    background-color: var(--gray100);
    padding-right: 24px;
  }
  &__right{
    padding-left: 2rem;
    margin-top: 0 !important;
    padding-right: 24px;
  }
  &__Category {
    margin: .8rem 0;
    &__container{
       display: flex;
       justify-content: space-between;
       background-color: var(--blue700);
       padding: 0.5rem .8rem !important;
       color: white;
    }
    &__items{
      padding: 0.25rem .8rem !important;
    }
  }
}
