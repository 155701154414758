.Overview {
  margin-top: 1rem;

  &__container {
    padding: 16px;
    display: flex;
    gap: 1.5rem;
  }

  &__left {
    gap: 1.5rem;
  }
}

.WorkOrders {
  &__stats {
    background-color: var(--gray100);
    padding: 1rem;
    border-radius: 12px;
  }
}