.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 2rem;
}

.header {
    display: flex;
    align-items: center;
    flex-direction: row;
    border: 1px solid black;
}

.body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
}

.headerItemRef {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 12.5%;
    border-right: 1px solid black;
    padding: 8px;
}

.headerItemNotes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 87.5%;
    padding: 8px;
}

.bodyItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid black;
    min-height: 5vh;
}

.existingBodyItem {
    background-color: #FEDBBB;
}

.bodyItemCellRef {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 12.5%;
    border-left: 1px solid black;
    padding: 8px;
    height: 100%;
}

.bodyItemCellNotes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 100%;
    border-inline: 1px solid black;
    padding: 8px;
}

.bodyItemCellButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 7.5%;
    height: 100%;
    border-right: 1px solid black;
    padding: 8px;
}

.addButton {
    padding: 4px 16px;
    background-color: var(--blue700);
    color: white;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}