// styles.scss
.contactInfoBox {
    background-color: #F9FAFA;
    ;
    padding: 16px;
    border-radius: 8px;
}

.contactInfoBoxTitle {
    cursor: pointer;
}

.title {
    @extend .contactInfoBoxTitle;
}

.info {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.icon {
    margin-right: 8px;
}

.expandIcon {
    margin-left: auto;
    transition: transform 0.2s;
    background-color: #F9FAFA;
    ;
    margin-right: 8px;
    position: relative;
    top: 5px;
}

.expanded {
    transform: rotate(180deg);
}

.titleContainer {
    display: flex;
    justify-content: space-between;
}