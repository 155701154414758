.FormRadioButton {
  border: 2px solid #ebecef;
  border-radius: 8px;
  flex-direction: row-reverse;
  padding: 1rem;
  // width: 50%;
  justify-content: space-between;
  margin-right: 0 !important;
  margin-left: 0 !important;
  height: fit-content;
  @media screen and (max-width: 1035px) {
    padding: 16px;
  }
  :global(.MuiButtonBase-root) {
    padding: 0 !important;
    height: fit-content !important;
  }
  :global(.MuiTypography-caption) {
    font-size: 18px;
    font-family: 'LatoNormal';
  }
}
