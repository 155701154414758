.title {
    font-size: 18px !important;
    font-family: "LatoBold" !important;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 8px;
}

.inputLabel {
    font-size: 16px !important;
    font-family: "LatoBold" !important;
}

.input {
    border: 2px solid #F5F6F7;
    margin-right: 0px !important;
    padding: 8px 16px;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    height: 100%;
}