.Container {
  padding: 0.2rem 0.5rem !important;
  border-radius: 8px !important;
  border: 2px solid var(--border-color) !important;
  box-shadow: 0px 1px 1px rgb(26 42 85 / 24%), 0px 0px 1px rgb(26 42 85 / 31%);
  &:hover {
    background-color: transparent !important;
  }
}
.Text {
  padding: 0;
}
