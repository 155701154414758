.TablePagination {
    :global(.MuiButtonBase-root) {
        background-color: transparent !important;
        color: var(--navy-700);
    }
}

.sticky {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 2;
}