@font-face {
  font-family: 'Nouvelle';
  src: url('./Nouvelle-Medium.otf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'NouvelleBold';
  src: url('./Nouvelle-Bold.otf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'NouvelleLight';
  src: url('./Nouvelle-Light.otf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'NouvelleNormal';
  src: url('./Nouvelle-Normal.otf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'Moranga';
  src: url('./Moranga-Medium.otf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'MorangaRg';
  src: url('./Moranga-Regular.otf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'BetterSansBold';
  src: url('./BetterSans-Bold.otf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'BetterSansLight';
  src: url('./BetterSans-Light.otf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'BetterSansMedium';
  src: url('./BetterSans-Medium.otf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'BetterSansRegular';
  src: url('./BetterSans-Regular.otf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'NextArt';
  src: url('./NEXT\ ART_SemiBold.otf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'NextArtBold';
  src: url('./NEXT\ ART_Bold.otf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'NextArtLight';
  src: url('./NEXT\ ART_Light.otf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'NextArtNormal';
  src: url('./NEXT\ ART_Regular.otf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'NextArtHeavy';
  src: url('./NEXT\ ART_Heavy.otf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'LatoNormal';
  src: url('./Lato-Regular.ttf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'LatoBold';
  src: url('./Lato-Bold.ttf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'LatoBlack';
  src: url('./Lato-Black.ttf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'LatoLight';
  src: url('./Lato-Light.ttf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./Lato-Regular.ttf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}