.Pros__Table {
  scroll-padding: 0;
  overflow-y: overlay;
  overflow-x: overlay;
  max-height: 60vh;
  border: 2px solid var(--border-color);

  :global(.MuiTable-root) {
    border: none !important;
    :global(.MuiTableCell-head) {
      border-top: 0;
    }
    :global(.MuiTable-root) {
      :global(.MuiTableRow-root) {
        :global(.MuiTableCell-root) {
          width: 1% !important;
          white-space: nowrap;
          padding: inherit 0.5rem !important;
        }
      }
    }
  }
}
