.Dispatch__container {
  .Title {
    text-align: center;
    padding: 4px 4px 8px 4px;
    color: var(--blue700);
    font-size: var(--font-M) !important;
    font-family: var(--font-bold) !important;
  }

  .SubTitle {
    text-align: center;
    font-size: var(--font-M) !important;
    font-family: var(--font-bold) !important;
    padding: 4px;
  }

  .Paragraph {
    text-align: center;
  }

  .Actions {
    display: flex;
    justify-content: center;
    gap: 8px;
    padding-top: 16px;
  }

  .Action {
    width: 180px;
  }
}

.Content {
  margin: 1rem 0;
}
