.JobsTasks__Table {
  :global(.MuiTableRow-root) {
    :global(.MuiTableCell-root) {
      &:first-child, &:last-child/*  &:nth-child(6)  */ {
        width: 1% !important;
        white-space: nowrap;
        padding: inherit .5rem !important;
      }
    }
  }
}
