.RadioGroup {
  flex: 1;
  :global(.MuiTypography-root){
    color: var(--gray700);
  }
  &.outlined {
    :global(.MuiFormControlLabel-root) {
      border: 2px solid var(--border-color);
      box-shadow: 0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31);
      padding: 1.15rem .4rem;
      border-radius: 8px;
      flex: 1;
      justify-content: space-between;
    }
    :global(.MuiRadio-root){
      padding: 0;
    }
  }
}
