.FilterToggler {
  &__container {
    color: #3A66FF;
    border-radius: 4px;
    width: fit-content !important;
    background-color: #FAFBFF;
    padding: 3px 8px;
    margin-left: 0px;
    :hover {
      color: black;
      cursor: pointer;
    }
  }
  &__label {
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
    margin-left: 10px !important;
  }
  &__item {
    padding: 0 !important;
    width: fit-content;
    color: inherit;
    justify-content: center;
    align-items: center;
  }
}
