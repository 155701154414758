 .Folder {
     flex-wrap: nowrap !important;
     width: fit-content !important;
     cursor: pointer;

     &__name {
         line-height: 1 !important;
         display: flex;
         justify-content: center;
         align-items: flex-end;
     }

     &__Menu {
         :global(.MuiPaper-root) {
             border: 2px solid var(--border-color);
             border-radius: 8px;
         }

         :global(.MuiList-root) {
             padding: 0;
         }
     }

     &__MenuItem {
         color: var(--black-color) !important;
         padding: .5rem !important;
         padding-right: 2rem !important;

         &--empty {
             opacity: .5;
         }
     }
     &__noClickable{
         :global(.MuiTouchRipple-root){
             display: none;
         }
     }
 }