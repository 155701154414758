.ImagePicker {
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 8px;
  border-width: 2px;
  border-color: var(--gray400);
  background: var(--gray100);
  position: relative;

  &_add {
    position: relative;
  }
  &__img {
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
    cursor: pointer;
  }
  &__deleteButton {
    position: absolute;
    top: 14px;
    right: 14px;
    width: 10px;
    height: 10px;
    border-radius: 4px;
    padding: 8px;
  }
 
}

.hiddenInput {
  display: none;
}
