.Dispatch__container {
  :global(.MuiDialogContent-root) {
    display: flex;
    flex-direction: column;
  }
}

.Title {
  text-align: center;
  padding: 8px;
  background: var(--blue700);
  margin: 0 -12px !important;
  color: var(--white-color);
}

.SubTitle {
  text-align: center;
  padding: 16px;
}

.Trades {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 12px;
  overflow: scroll;
  max-height: 100%;
  min-height: 0;
}

.Trade {
  padding: 12px;
  border-radius: 8px;
  background-color: var(--gray100);
  cursor: pointer;

  &__Selected {
    border: 2px solid var(--blue700);
    display: flex;
    gap: 8px;
  }
}

.Actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.Action {
  width: 180px;
}
