.Comments {
  &__button {
    position: fixed !important;
    bottom: 2rem !important;
    right: 3rem !important;
    width: 62px !important;
    height: 62px !important;
    border-radius: 72px !important;
    padding: 1rem !important;

    transition: width 1s ease-in-out !important;
    &:hover {
      background-color: var(--purple500) !important;
      width: 180px !important;
      transition: width 1s ease-in-out !important;
    }

    &__text {
      font-size: 24px !important;
      margin-left: 0.5rem !important;
      transition: width 200ms ease-in-out !important;
    }
  }

  &__modal {
    :global(.MuiDialog-container) {
      margin: 0;
      position: absolute;
      right: 0;
      max-width: 100vw;
      :global(.MuiPaper-root) {
        height: 100vh;
        width: 50vw;
        max-height: 100%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        margin: 0;
      }
    }
  }
}
