.ServiceDetail {
  // margin-top: 2rem !important;
  flex-wrap: nowrap !important;
  &__container {
    padding: 1.5rem;
    border: 2px solid var(--border-color);
    border-radius: 0px 0px 8px 8px;
    border-top: 0;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      background: var(--border-color);
      width: 100%;
      right: 0;
      top: -2px;
    }
  }

  &__button {
    min-width: 120px !important;
  }
}
