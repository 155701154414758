.TextFieldLabel {
    :global(.MuiOutlinedInput-input){
        padding: 8.5px;
    }
}

.contactItem {
    justify-items: center;
    margin-right: 10px;
    align-items:center;
}

.contactIconItem {
     margin-left: 3px;
     margin-right: 3px;
     align-items:center;
}

.icon {
    position: relative;
    top: 3px;
    margin-right: 4px;
}

