.BackButton {
  cursor: pointer;
  color: rgb(58, 102, 255);

  &__Float {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    left: 0;
    background: rgba(240, 243, 250, 1);
    border-radius: 0px 8px 8px 0px;
    padding: 2px 4px 2px 2px;
  }

  &__Large {
    width: 76px;
    height: 46px;
    display: flex;
    justify-content: space-evenly;
    
    &:hover {
      background: rgb(231, 238, 255);
    }

    span {
      font-size: 14px;
      color: rgb(58, 102, 255);
    }
  }
}