.Comment {
  margin-bottom: .8rem;

  &__box {
    padding: .4rem;
    border-radius: 8px;
    margin-bottom: .3rem !important;

    background: var(--lightBlue100);
    border: 2px solid var(--blue400);
  }

  &__boxGreen {
    padding: .4rem;
    border-radius: 8px;
    margin-bottom: .3rem !important;

    background: var(--green100);
    border: 2px solid var(--green600);
  }
}