.Communications {
  &__description {
    font-family: var(--font-normal) !important;
    font-style: 'normal';
    font-weight: 400;
    font-size: var(--font-XS) !important;
    line-height: '22px !important';
    line-break: anywhere;
    color: '#1A2A55';
  }

  &__createdOn {
    font-family: var(--font-normal) !important;
    font-style: 'normal';
    font-weight: 400;
    font-size: var(--font-XXS) !important;
    line-height: '18px !important';
    line-break: anywhere;
    color: '#72748D !important';
  }

  &__viewButton {
    width: 91px;
    height: 32px !important;
  }

  &__tableContainer {
    height: calc(100vh - 270px);
    overflow: overlay;
  }
}
