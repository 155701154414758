.container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.paper {
  padding: 8px;
  width: 100%;
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infoLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon {
  margin-right: 8px;
}

.deleteBtn {
  color: red;
}
