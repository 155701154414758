.Folders {
    background: var(--gray100);
    padding: 8px 24px;
    &__Input {
        :global(.MuiInput-underline) {
            &::before,
            &::after {
                border-bottom: none;
            }
        }
    }

    &__Container {
        align-items: center;
        flex: 1;
        gap: 1rem;
    }

    &__Folder {
        flex-wrap: nowrap !important;
        width: fit-content !important;
        cursor: pointer;
        &__name {
            line-height: 1 !important;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }
    }

    &__DropDown{
        :global(.MuiAccordionDetails-root){
            padding-left: 2rem;
        }
        :global(.MuiTypography-root){
            width: 100%;
            text-align: left;
        }
        :global(.MuiAccordionSummary-root){
            flex-direction: row-reverse;
        }
        :global(.MuiAccordionSummary-content){
            flex: 1
        }
    }
}
