.LinkEstimate {
    &__container {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        padding: 8px;
        border: 2px solid #EBECEF;
        box-shadow: 0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31);
        border-radius: 8px;
        margin-top: 0px !important;
        margin-left: 0px !important;
        width: 100% !important;
    }

    &__publicId {
        font-size: 14px;
        line-height: 150%;
        color: #3A66FF;
        width: fit-content;
    }

    &__icon {
        width: 14px;
        height: 14px;
        margin-left: 3px !important;
    }
}