// styles.module.scss

.boxContainer {
    background-color: #F9FAFA;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    width: 100%;
    border-radius: 8px;
    margin: 10px;
}

.text {
    text-align: center;
}