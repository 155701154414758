.Header {
  gap: 16px;
  background: var(--gray100);
  padding: 26px 98px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto auto 1fr 2fr;
}

.Content {
  padding: 16px 24px 0 !important;
  justify-content: space-between;
}

.Footer {
  display: flex;
  gap: 16px;
  padding: 16px;
  justify-content: flex-end;
  width: 100%;
}

.Link {
  color: var(--blue700) !important;
  text-decoration: none !important;
  padding: 8px !important;
  cursor: pointer !important;
}

.Table {
  max-width: 100% !important;
  overflow-x: scroll !important;
}