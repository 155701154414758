.Overview {
  margin-top: 1rem;

  &__container {
    padding: 16px;
    display: flex;
    gap: 1.5rem;
  }

  &__left {
    gap: 0;
  }

  &__ticketLink {
    background-color: #ff3547!important;
    color: white;
    border: 0 !important;
    font-size: var(--font-XS) !important;
    font-family: var(--font-normal) !important;
  }

  &__requestLink {
    background-color: #00c851!important;
    color: white;
    border: 0 !important;
    font-size: var(--font-XS) !important;
    font-family: var(--font-normal) !important;
  }
}

.WorkOrders {
  &__stats {
    background-color: var(--gray100);
    padding: 1rem;
    border-radius: 12px;
  }
}