.selectAddress {
  height: fit-content;
  border: 2px solid var(--border-color) !important;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgba(26, 42, 85, 0.24),
    0px 0px 1px rgba(26, 42, 85, 0.31);
  :global(.MuiInputBase-root) {
    &:before {
      display: none;
    }
  }
  :global(.MuiInputBase-root input) {
    padding: 4px 12px !important;
  }
  :global(.MuiInputBase-root input::placeholder) {
    color: currentColor;
  }
  :global(.MuiInputBase-root:after) {
    border-bottom: none !important;
  }
}
