.Companies {
  &__companyName {
    font-family: var(--font-bold) !important;
    font-style: 'normal';
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    line-break: anywhere;
  }

  &__createdOn {
    font-family: var(--font-normal) !important;
    font-style: 'normal';
    font-weight: 400;
    font-size: var(--font-XXS) !important;
    line-height: '18px !important';
    line-break: anywhere;
    color: '#72748D !important';
  }

  &__viewButton {
    width: 91px;
    height: 32px !important;
  }

  &__tableContainer {
    height: calc(100vh - 270px);
    overflow: overlay;
  }
}

.TaskTitle {
  font-family: var(--font-bold) !important;
  font-style: 'normal';
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  line-break: anywhere;
}

.TaskHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  background-color: var(--gray100);
  border-bottom: 2px solid var(--gray200);
}

.TaskRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.TaskTitleContainer {
  width: 60%;
  display: flex;
  padding: 0px 8px;
  align-items: center;
  border-right: 2px solid var(--gray200);
}

.TaskValueContainer {
  width: 40%;
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
}