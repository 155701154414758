.EstimateInfo {
    &__dropdown {
        margin-bottom: 1rem;
        gap: 16px
    }

    &__checkbox {
        flex-direction: column-reverse;
    }
}

.ranges {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 1rem;

    >div {
        width: 48%;
    }
}

.textFieldFont {
    font-size: 14px;
}

.priceMatchFileName {
    border: 2px solid var(--green700);
    border-radius: 8px;
    background-color: var(--green100);
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    width: 100%;
}

.priceMatchFileDelete {
    cursor: pointer;
}