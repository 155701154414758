.Error {
  background-color: var(--error-color) !important;
  color: #fff !important;
}

.Tabs {
  margin: 1rem 0;

  &.text {
    :global(.MuiButtonBase-root) {
      max-width: 100%;
    }
  }

  &.contained,
  &.containedCircular {
    border: 2px solid var(--border-color);
    border-radius: 8px;

    :global(.MuiTabs-flexContainer) {
      display: grid;
      justify-content: space-evenly;
    }

    :global(.MuiButtonBase-root) {
      max-width: 100%;
      font-size: var(--font-XXS);
      min-height: fit-content;
      padding: 0.4rem;
    }

    :global(.Mui-selected) {
      background-color: var(--blue700) !important;
      color: white !important;
    }
  }

  &.containedCircular {
    border-radius: 80px;
    padding: 8px;

    :global(.MuiButtonBase-root) {
      border-radius: 80px;
    }
  }

  &.outlined {
    :global(.MuiTabs-flexContainer) {
      gap: 0.5rem;
      //border-bottom: 2px solid var(--border-color);
    }

    :global(.MuiButtonBase-root) {
      max-width: 100%;
      font-size: var(--font-XXS);
      min-height: fit-content;
      padding: 0.4rem;
      background: var(--gray100);
      border-radius: 8px 8px 0px 0px;
      border: 2px solid var(--border-color);
      border-bottom: none;
      overflow: visible;
      color: var(--gray500);
      border-bottom: 2px solid var(--border-color);
    }

    :global(.Mui-selected) {
      background-color: transparent;
      color: var(--black-color) !important;
      position: relative;
      z-index: 9;
      border-bottom: 3px solid white;

      :global(.MuiTypography-root) {
        font-family: var(--font-bold);
      }
    }
  }
}

.outlined {
  &--container {

    // border-bottom: 2px solid var(--border-color);
    &--scrollable {
      display: flex !important;
      justify-content: flex-start !important;
      flex-wrap: nowrap !important;
      // width: 100% !important;
    }
  }
}

.Tab {
  &:global(.Mui-selected) {
    color: var(--blue700) !important;
  }
}

.Expand {
  background-color: var(--navy-700) !important;
  color: var(--white-color) !important;
  border-radius: 8px 8px 0px 0px;
  width: 74px !important;
  margin-left: 0.4rem !important;
  border-radius: 8px 8px 0px 0px !important;

  &.outlined::before {
    content: '';
    position: absolute;
    bottom: -1px;
    height: 2px;
    background: var(--border-color);
    width: 95vw;
    // z-index: -1;
    right: 0;
  }
}

.Expanded {
  position: absolute;
  top: 0 !important;
  padding: 0px 48px 24px 0px;
  background: white;
  transition: top 3s linear;
}

:global(.MuiTabs-scrollButtons) {
  border: 2px solid #0071a3 !important;
  border-bottom: none !important;
  width: 50px !important;
  // margin: 0 .4rem !important
}

.Column {
  gap: 2rem;
}