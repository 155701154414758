.TimePickers {
  display: flex;
  :global(.swiper-slide) {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    transition: opacity 0.3s ease;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  :global(.swiper-wrapper) {
    margin-right: 0.5rem;
    width: fit-content;
  }
  :global(.swiper-slide-active) {
    background-color: var(--blue700);
    color: white;
    border-radius: 2px;
  }
}

.TimePicker {
  &__Hours {
    max-height: 220px;
    :global(.swiper-slide-active) {
      border-top-left-radius: 8px;
    }
  }
  &__Minutes {
    max-height: 125px;
  }
  &__Period {
    max-height: 62px;

    :global(.swiper-slide-active) {
      border-top-right-radius: 8px;
    }
  }
  &__label {
    padding: 1rem 1.5rem;
  }
}
:global(.slider-arrow){
  cursor: pointer;
  padding: .4rem;
}