.Notes {
  &__Input {
    border: 2px solid var(--border-color);
    padding: 8px;
    border-radius: 8px;
    resize: none;
    &_edit {
      :global(.MuiOutlinedInput-root) {
        border: 2px solid #ad7d00;
        background-color: #fffbf0;
      }
    }
  }
  &__noteBy {
    display: block;
    margin-top: 0.5rem;
    text-align: end;
  }
}
