.Comment {
  margin-bottom: 0.8rem;

  &__box {
    padding: 0.4rem;
    border-radius: 8px;
    margin-bottom: 0.3rem !important;

    background: var(--lightBlue100);
    border: 2px solid var(--blue400);
  }

  &__boxGreen {
    padding: 0.4rem;
    border-radius: 8px;
    margin-bottom: 0.3rem !important;

    background: var(--green100);
    border: 2px solid var(--green600);
  }

  &__text {
    word-wrap: break-word !important;
    display: block !important;
    width: 100% !important;
    padding-right: 12px;
  }
}
