
.Table {
  :global(.MuiTable-root) {
    border: none;
    border-radius: 0;
  }

  :global(.MuiTableRow-root) {
    position: relative;
	}

  :global(.MuiTableCell-body) {
    vertical-align: baseline;
    padding-bottom: 4.5rem !important;
  }
}

.Actions {
	position: absolute;
  display: flex;
  right: 40px;
  bottom: 8px;
  gap: 8px;
}

.DropDown {
  :global(.MuiAccordionSummary-root) {
    background-color: var(--blue700);
    padding: 0 0.5rem !important;
  }
  :global(.MuiAccordionSummary-expandIconWrapper) {
    :global(.MuiSvgIcon-root) {
      color: white !important;
    }
  }
}