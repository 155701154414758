.Images {
  &__deleteButton {
    position: absolute;
    top: 14px;
    right: 14px;
    width: 10px;
    height: 10px;
    border-radius: 4px;
    padding: 8px;
  }
  &__image {
    object-fit: cover;
    padding: 8px 8px 0 8px;
    border-radius: 16px;
    width: 100%;
    cursor: pointer;
  }
}

