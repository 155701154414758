.RecoverRemovedItems {
  &__container {
    > :global(.MuiGrid-root):first-child {
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
    }
    > :global(.MuiGrid-root):last-child {
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
    }
  }
}

.OrderItems__container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
