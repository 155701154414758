.InspectionData {
  margin-top: 1rem;
  &__box{
    display: flex !important;
    gap: 2rem !important
  }
  &__button{
    padding: 0 !important;
    width: 31px !important;
    height: 31px !important;
    border-radius: 100% !important;
    :global(.MuiButton-endIcon){
      margin-left: 0;
    }
  }
}
