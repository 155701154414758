.Subscriptions {
  &__container {
    display: flex;
    flex-flow: column;
    gap: 16px;
    height: calc(100vh - 128px);
  }
  &__cell {
    min-height: 50px height;
  }
}
