.TableCell {
    &:last-child {
        border-bottom: none;
    }

    &__noBorders {
        border-bottom: none !important;
        border-right: none !important;
    }
}

.TableRow {
    &__clickable {
        cursor: pointer;

        &:hover {
            background-color: var(--lightBlue200);
        }
    }

    &__highlightedRows {
        &:nth-of-type(odd) {
            background-color: white,
        }

        &:nth-of-type(even) {
            background-color: var(--gray200),
        }
    }

}